const TowRowsPoints = (
    {heading, body}
) => {
    return (
        <>
            <div className="row">
                <div className="col-2">
                    <div 
                        style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "100%",
                            backgroundColor: 'orange',
                        }}
                    ></div>
                </div>
                <div className="col-10">
                    <h3>{heading}</h3>
                    <p>{body}</p>
                </div>
            </div>
        </>
    );
}
 
export default TowRowsPoints;
import { Link } from 'react-router-dom';

const Header = ({ user }) => {
    return (
        <div className="row">
            <div className="col">
                <h1>Welcome {user?.name}</h1>
            </div>
            <div className="col">
                <Link to="/admin/login" className='btn btn-success' style={{ float: "right", marginTop: "10px" }}>Logout</Link>
            </div>
        </div>
    );
}
 
export default Header;
import { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import config from "../../Config";

const DownloadFile = () => {
    const { id, type } = useParams();

    const navigate = useNavigate();

    const fileDownload = (id) => {
        // Redirect to the desired route
        fetch(config.baseUrl + "/api/file-download/" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        })
        .then(async (res) => {
            if (res.ok) {
                console.log("hey", id, type);
                // Use the res to trigger the file download
                const blob = await res.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `file.${type}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                navigate('/');
            } else {
                console.error('Failed to download file:', res.statusText);
            }
        })
    };

    useEffect(()=>{
        fileDownload(id);
    }, []);
    return (
        <>
            <center style={{
                color: "white",
                padding: "50px",
            }}>
                <h1>File is being Downloaded</h1>
            </center>
        </>
    );
}
 
export default DownloadFile;
import React, { useEffect, useState } from 'react';
import config from '../Config';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        // Add your login logic here
        console.log('Logging in with:', { email, password });
        fetch(config.baseUrl + "/api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers as needed
            },
            body: JSON.stringify({
                email,
                password
            }),
        }).then(res=>res.json())
        .then((res)=>{
            console.log(res);
            if (res.status === "success"){
                localStorage.setItem('token', res.token);
                navigate('/admin/home');
            }
        })
    };

    var logout = () => {
        fetch(config.baseUrl + "/api/logout", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
                // Add any additional headers as needed
            },
        }).then(res => res.json())
        .then((res) => {
            console.log(res);
            localStorage.setItem('token', null);
        })
    }

    useEffect(() => {
        logout();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ 
                width: '300px', 
                textAlign: 'left', 
                backgroundColor: "white",
                borderRadius: "10px", 
                padding: "55px" 
            }}>
                <h2>Login</h2>
                <label>
                    Email: <br />
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
                <br />
                <label>
                    Password: <br />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <br />
                <button className="btn btn-success" onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;

const ProgressBar = ({value}) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <progress 
                        value={value} max={100}
                        autoIncrement
                        style={{ 
                            color: 'pink', 
                            height: '20px',
                            width: "100%",
                            borderRadius: "20px",
                        }}
                    ></progress>
                </div>
            </div>
        </div>
    );
}
 
export default ProgressBar;
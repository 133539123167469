import { useNavigate } from 'react-router-dom';

const StartQuoteButtonNoBg = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        // Redirect to the desired route
        navigate('/getting-started');
    };
    return (
        <button 
            className="start-quote start-quote-no-bg"
            onClick={handleClick}    
        >Start Quote</button>
    );
}
 
export default StartQuoteButtonNoBg;
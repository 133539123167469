import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import config from '../../Config';
import Header from '../Header';
import MainInfo from './MainInfo';
import QuestionsDropDown from './QuestionsDropDown';

const Client = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    
    const navigate = useNavigate();

    var getClient = () => {
        fetch(config.baseUrl+"/api/get-client/"+id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        }).then(res=>res.json())
        .then((res)=>{
            console.log(res)
            if (res.status === "unauthorized") {
                navigate("/admin/login");
            }
            setData(res);
        })
    }

    const fileDownload = (id, fileName) => {
        // Redirect to the desired route
        fetch(config.baseUrl + "/api/file-download/" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        })
        .then(async (res) => {
            if (res.ok) {
                // Use the res to trigger the file download
                const blob = await res.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Failed to download file:', res.statusText);
            }
        })
    };

    useEffect(()=>{
        getClient();
    }, [])

    return (
        <div className="container" style={{ color: "white", paddingTop: "20px" }}>
            {data && 
                <>
                <Header user={data?.user} />
                <Link to="/admin/home" className='btn btn-success' style={{ marginTop: "10px" }}>Home</Link>
                <br />
                <br />
                <div className="row" style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "30px",
                    borderRadius: "30px",
                }}>
                    <div className="col-12 col-lg-7 col-md-8">
                        <MainInfo title="Name" value={data?.client?.name} />
                        <MainInfo title="Email" value={data?.client?.email} />
                        <MainInfo title="Phone Number" value={data?.client?.phone_number} />
                    </div>
                    <div className="col-12 col-lg-5 col-md-4" style={{"borderLeft": "1px solid grey"}}>
                        <h3>Files</h3>
                        {data?.client?.files?.map((file,i)=>(
                            <button
                                className="btn btn-success mr-2 mt-2"
                                key={i}
                                onClick={() => { fileDownload(file.id, file.file)}}
                            >
                                {file.file}
                            </button>
                        ))}
                    </div>
                </div>
                <br />
                {data?.client?.questions?.map((question, index)=>(
                    <QuestionsDropDown 
                        key={index} 
                        question={question.question} 
                        answers={question.answer} />
                ))}
                <br />
                </>
            }
        </div>
    );
}
 
export default Client;
import OneBox from "./OneBox";
import oneBox1 from "../../images/one-box-1.jpg";
import oneBox2 from "../../images/one-box-2.jpg";
import oneBox3 from "../../images/one-box-3.jpg";
import oneBox4 from "../../images/one-box-4.jpg";

const FourBoxes = () => {
    return (
        <div className="container-fluid four-boxes">
            <center>
                <div className="row">
                    <div className="col">
                        <div className="vertical-space-7"></div>
                        <h1>Custom Tailored Insurance</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-8">
                        <p className="main-card-p">
                            We make getting trucking insurance simple.
                            Click any of the images below to submit for a quick quote.
                            We look forward to insuring you.
                        </p>
                    </div>
                    <div className="col-12 col-md-2"></div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5">
                        <OneBox 
                            color="red"
                            icon=""
                            text="Trucking Insurance"
                            bg={oneBox1}
                            textColor={"white"}
                            onHoverBgColor={"#ff9c9c"}
                            onHoverColor={"white"}
                        />
                    </div>
                    <div className="col-12 col-md-1"></div>
                    <div className="col-12 col-md-5">
                        <OneBox
                            color="green"
                            icon=""
                            text="General Liability"
                            bg={oneBox2}
                            textColor={"white"}
                            onHoverBgColor={"#9cffa1"}
                            onHoverColor={"white"}
                        />
                    </div>
                    <div className="col-12 col-md-1"></div>
                    <div className="col-12 col-md-5">
                        <OneBox
                            color="blue"
                            icon=""
                            text="Workers Comp"
                            bg={oneBox3}
                            textColor={"white"}
                            onHoverBgColor={"cadetblue"}
                            onHoverColor={"white"}
                        />
                    </div>
                    <div className="col-12 col-md-1"></div>
                    <div className="col-12 col-md-5">
                        <OneBox
                            color="yellow"
                            icon=""
                            text="Freight Broker Bond"
                            bg={oneBox4}
                            textColor={"white"}
                            onHoverBgColor={"#9cffa1"}
                            onHoverColor={"white"}
                        />
                    </div>
                    <div className="col-12 col-md-1"></div>
                </div>
            </center>
            <br /><br /><br />
        </div>
    );
}
 
export default FourBoxes;
import React, { useEffect, useState } from 'react';
import config from '../Config';
import { useNavigate, Link } from 'react-router-dom';
import Table from '../Table';
import Header from './Header';

const Home = () => {

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: '',
                accessor: 'checkbox', // Placeholder for actions
                Cell: ({ row }) => (
                    <center>
                        <input type="checkbox" onChange={(e)=>checkboxChange(e, row.original.id)} />
                    </center>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Phone Number',
                accessor: 'phone_number',
            },
            {
                Header: 'Actions',
                accessor: 'actions', // Placeholder for actions
                Cell: ({ row }) => (
                    <div>
                        <Link to={`/admin/client/${row.original.id}`} className="btn btn-primary btn-sm mr-2">
                            Details
                        </Link>
                        <button onClick={() => deleteClient(row.original.id)} className="btn btn-danger btn-sm">
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const rowsData = React.useMemo(() => data?.clients || [], [data]);

    var deleteClient = (id) => {
        console.log("deleteClient", id);
        fetch(config.baseUrl + "/api/delete-client/" + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        }).then(res => res.json())
        .then((res) => {
            console.log(res);
            if (res.status === "unauthorized") {
                navigate("/admin/login");
            }
            // setData(res);
            getData();
        })
    }

    var checkboxChange = (e, id) => {
        console.log("checkboxChange", e.target.checked, id);

        var updatedSelectedRows = selectedRows;
        updatedSelectedRows[id] = e.target.checked;

        setSelectedRows(updatedSelectedRows);
    }

    var getData = () => {
        fetch(config.baseUrl + "/api/get-data", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        }).then(res => res.json())
        .then((res) => {
            console.log(res);
            if (res.status === "unauthorized"){
                navigate("/admin/login");
            }
            setData(res);
        })
    }

    var exportAllPdf = () => {
        var clients = data.clients;
        exportRequest(clients);
    }

    var exportPdf = () => {
        var clients = data.clients.filter(client => selectedRows[client.id]);
        exportRequest(clients);
    }

    var exportRequest = (clients) => {
        fetch(config.baseUrl + "/api/donwload-pdf", {
            method: 'POST',
            body: JSON.stringify({
                download_file_url: `${config.baseUrlFrontend}/admin/download-file`,
                clients: clients,
            }),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        })
        .then(async (res) => {
            if (res.ok) {
                // Use the res to trigger the file download
                const blob = await res.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                // a.download = "clients.pdf";
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Failed to download file:', res.statusText);
            }
        })
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className='container' style={{color: "white", paddingTop: "20px"}}>
            {data && 
                <>
                <Header user={ data?.user }/>
                <br /><br />
                <section style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "50px",
                    paddingTop: "30px",
                    borderRadius: "30px",
                }}>
                    <div className="row">
                        <div className="col">
                            <h3>Clients</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button 
                                className='btn btn-success'
                                onClick={exportAllPdf}
                            >
                                Export All Pdf
                            </button>
                            <button 
                                className='btn btn-success ml-3'
                                onClick={exportPdf}
                            >
                                Export Selected Pdf
                            </button>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <Table columns={columns} data={rowsData} />
                        </div>
                    </div>
                </section>
                </>
            }
        </div>
    );
}
 
export default Home;
import mainLogo from "../images/main-logo.png";
import StartQuoteButton from "./StartQuoteButton";

const TopBar = () => {
    return (
        <div className="top-bar">
            <div className="container">
                <center>
                    <div className="row">
                        <div className="col">
                            <img src={mainLogo} className="logo" alt="logo" />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-12 col-md-3">
                            <StartQuoteButton />
                        </div>
                        <div className="col"></div>
                    </div>
                    <br/>
                </center>
            </div>
        </div>
    );
}
 
export default TopBar;
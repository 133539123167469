import MainCard from "./MainCard";
import TopBar from "./TopBar";
import Footer from "./footer/Footer";
import FourBanners from "./fourBanners/FourBanners";
import FourBoxes from "./fourBoxes/FourBoxes";
import LongBanner from "./longBanner/LongBanner";
import TowRows from "./twoRows/TwoRows";

const Home = () => {
    return (
        <>
            <div className="App">
                <div className="background-image"></div>
                <TopBar />
                <MainCard />
                <FourBoxes />
                <TowRows />
                <LongBanner />
                <FourBanners />
                <Footer />
            </div>
        </>
    );
}
 
export default Home;
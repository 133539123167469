const MainInfo = ({title, value}) => {
    return (
        <div className="row" style={{
            fontSize: "24px",
            fontWeight: "bold"
        }}>
            <div className="col-6">
                {title}
            </div>
            <div className="col-6">
                {value}
            </div>
        </div>
    );
}
 
export default MainInfo;
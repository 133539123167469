import StartQuoteButton from "./StartQuoteButton";
import StartQuoteButtonNoBg from "./StartQuoteButtonNoBg";

const MainCard = () => {
    return (
        <>
            <div className="vertical-space-22"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-7"></div>
                    <div className="col-12 col-lg-5">
                        {/* <div className="main-card-shadow">
                        </div> */}
                        <div className="main-card">
                            <div className="row">
                                <div className="col">
                                    <h1 className="main-card-h1">Welcome to Commercial Trucking Insurance Pros</h1>
                                    <br />
                                    <p className="main-card-p">Practical and affordable insurance</p>
                                </div>
                            </div>
                            <div className="vertical-space-7"></div>
                            <div className="row">
                                <div className="col">
                                    <StartQuoteButton />
                                </div>
                                {/* <div className="col">
                                    <StartQuoteButtonNoBg />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
        </>
    );
}
 
export default MainCard;
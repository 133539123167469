import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import config from '../Config';

import QuestionBody from "./QuestionBody";
import QuestionButtons from "./QuestionButtons";
import ProgressBar from "./ProgessBar";

const QuestionBox = () => {
    var initial_pages = [
        {
            page: 1,
            nextText: "Begin",
            statement: "Answer 7 Questions to Get Started with a Quote",
        },
        {
            page: 2,
            nextText: "Next",
            backText: "Back",
            question: "What is your DOT# ? or are you a New Venture?*",
            radios: [
                { text: "New Venture", value: false, id: "radios_2_1" },
                { text: "Enter DOT# Below", value: false, id: "radios_2_2" },
            ],
            input: "",
            input_id: "radios_2_2",
        },
        {
            page: 3,
            nextText: "Next",
            backText: "Back",
            question: "How Many Total Trucks ?*",
            radios: [
                { text: "1", value: false, id: "radios_3_1" },
                { text: "2 to 5", value: false, id: "radios_3_2" },
                { text: "5 to 10", value: false, id: "radios_3_3" },
                { text: "10 to 20", value: false, id: "radios_3_4" },
                { text: "20 to 50", value: false, id: "radios_3_5" },
                { text: "50 to 100", value: false, id: "radios_3_6" },
                { text: "100+", value: false, id: "radios_3_7" },
            ],
        },
        {
            page: 4,
            nextText: "Next",
            backText: "Back",
            question: "What do you Haul?*",
            checkboxes: [
                { text: "General Dry Freight", value: false, id: "checkboxes_4_1" },
                { text: "Refrigerated Goods", value: false, id: "checkboxes_4_2" },
                { text: "Hamzat, Chemicals, Fuel, Diesel, Gas", value: false, id: "checkboxes_4_3" },
                { text: "Sand, Gravel, Dirt, Asphalt", value: false, id: "checkboxes_4_4" },
                { text: "House Hold Goods", value: false, id: "checkboxes_4_5" },
                { text: "Flatbed Loads - Lumber, Steel, Machinery, Construction Equipment", value: false, id: "checkboxes_4_6" },
                { text: "Other", value: false, id: "checkboxes_4_7" },
            ],
            input: "",
            input_id: "checkboxes_4_7",
        },
        {
            page: 5,
            nextText: "Next",
            backText: "Back",
            question: "Radius ?*",
            checkboxes: [
                { text: "East Coast", value: false, id: "checkboxes_5_1" },
                { text: "West Coast", value: false, id: "checkboxes_5_2" },
                { text: "North West", value: false, id: "checkboxes_5_3" },
                { text: "North East", value: false, id: "checkboxes_5_4" },
                { text: "South West", value: false, id: "checkboxes_5_5" },
                { text: "South East", value: false, id: "checkboxes_5_6" },
                { text: "Local", value: false, id: "checkboxes_5_7" },
                { text: "Other", value: false, id: "checkboxes_5_8" },
            ],
            input: "",
            input_id: "checkboxes_5_8",
        },
        {
            page: 6,
            nextText: "Next",
            backText: "Back",
            question: "Auto Liability Limit ?*",
            checkboxes: [
                { text: "$300k", value: false, id: "checkboxes_6_1" },
                { text: "$500k", value: false, id: "checkboxes_6_2" },
                { text: "$750k", value: false, id: "checkboxes_6_3" },
                { text: "$1 Million", value: false, id: "checkboxes_6_4" },
                { text: "$1.5 Million", value: false, id: "checkboxes_6_5" },
                { text: "$5 Million", value: false, id: "checkboxes_6_6" },
                { text: "Other", value: false, id: "checkboxes_6_7" },
            ],
            input: "",
            input_id: "checkboxes_6_7",
        },
        {
            page: 7,
            nextText: "Next",
            backText: "Back",
            question: "Motor Truck Cargo Limit ?*",
            checkboxes: [
                { text: "25k", value: false, id: "checkboxes_7_1" },
                { text: "50k", value: false, id: "checkboxes_7_2" },
                { text: "75k", value: false, id: "checkboxes_7_3" },
                { text: "100k", value: false, id: "checkboxes_7_4" },
                { text: "150k", value: false, id: "checkboxes_7_5" },
                { text: "200k", value: false, id: "checkboxes_7_6" },
                { text: "250k", value: false, id: "checkboxes_7_7" },
                { text: "Other", value: false, id: "checkboxes_7_8" },
            ],
            input: "",
            input_id: "checkboxes_7_8",
        },
        {
            page: 8,
            nextText: "Next",
            backText: "Back",
            question: "Current Insurance Status ?*",
            checkboxes: [
                { text: "Shopping - Renewal Coming up", value: false, id: "checkboxes_8_1" },
                { text: "New Venture", value: false, id: "checkboxes_8_2" },
                { text: "Current Price is too High", value: false, id: "checkboxes_8_3" },
                { text: "Cancelled", value: false, id: "checkboxes_8_4" },
                { text: "Non Renewal", value: false, id: "checkboxes_8_5" },
                { text: "Other", value: false, id: "checkboxes_8_6" },
            ],
            input: "",
            input_id: "checkboxes_8_6",
        },
        {
            page: 9,
            sendText: "Send",
            backText: "Back",
            statement: "How do we contact you ?*",
            inputs: [
                { text: "Contact Name", value: "", id: "checkboxes_9_1" },
                { text: "Contact Email - - you will be contacted Same day or the next business day", value: "", id: "checkboxes_9_2" },
                { text: "Contact Phone Number - - you will be contacted Same day or the next business day", value: "", id: "checkboxes_9_3" },
            ],
            files: [],
            input: "",
            input_id: "checkboxes_9_3",
        },
        {
            page: 10,
            statement: "Thank you so much. We have received your information and someone will contact you soon.",
            homeText: "Home",
        },
    ];

    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [questionShow, setQuestionShow] = useState(true);
    const [pages, setPages] = useState(initial_pages);
    const [pageLength, setPageLength] = useState(initial_pages.length);
    const [disableButton, setDisableButton] = useState(false);

    var checkDisbaleButton = (toCheck, inputs) => {
        var selected = false;
        var allInputs = true;
        toCheck?.map((item)=>{
            console.log("checkDisabledButton", item);
            if (item.value === true && inputs === false){
                selected = true;
            } 
            
            if (item.value === "" && inputs === true){
                allInputs = false;
            }
        })

        if (inputs === true) selected = allInputs;

        setDisableButton(!selected);
    }

    var changeRadioValues = (id) => {
        pages[page]?.radios?.map((item) => {
            if (item.id === id) {
                console.log(id);
                item.value = true;
            } else {
                item.value = false;
            }
        });
        var newpages = {...pages};
        setPages(newpages);
        checkDisbaleButton(pages[page]?.radios, false);
    }
    var changeCheckboxValues = (id) => {
        pages[page]?.checkboxes?.map((item) => {
            if (item?.id === id) item.value = !item.value;
        });
        var newpages = { ...pages };
        setPages(newpages);
        checkDisbaleButton(pages[page]?.checkboxes, false);
    }

    var updatePage = (number) => {
        setQuestionShow(false);
        setPage(page + number);

        setTimeout(() => {
            setQuestionShow(true);
        }, 100);
        
        setDisableButton(true);
        if (pages[page + number]?.radios) checkDisbaleButton(pages[page + number]?.radios, false);
        if (pages[page + number]?.checkboxes) checkDisbaleButton(pages[page + number]?.checkboxes, false);
        if (pages[page + number]?.inputs) checkDisbaleButton(pages[page + number]?.inputs, true);
    }

    var updateTextValue = (val) => {
        if (pages[page]){
            pages[page].input = val;
            var newpages = { ...pages };
            setPages(newpages);
        }
    }

    var updateInputsValues = (val, index) => {
        if (pages[page]){
            if (pages[page].inputs){
                pages[page].inputs[index].value = val;
                var newpages = { ...pages };
                setPages(newpages);
                if (pages[page]?.inputs) checkDisbaleButton(pages[page]?.inputs, true);
            }
        }
    }

    var updateFilesValue = (files) => {
        if (pages[page]){
            var convertedFiles = pages[page]?.files ?? [];
            var filesLength = convertedFiles.length + files.length;
            const promises = Array.from(files).map((file) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const base64String = e.target.result;
                        const fileExtension = file.name.split('.').pop().toLowerCase();
                        var file_obj = {
                            file: base64String,
                            ext: fileExtension,
                        }
                        convertedFiles.push(file_obj);
                        resolve({ name: file.name, base64String, fileExtension });
                    };

                    reader.readAsDataURL(file);
                });
            });

            Promise.all(promises);

            pages[page].files = convertedFiles;
            var newpages = { ...pages };
            setPages(newpages);
            return filesLength;
        }
        return 0;
    }

    var saveData = () => {
        setQuestionShow(false);
        fetch(config.baseUrl + "/api/save-data", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers as needed
            },
            body: JSON.stringify(pages),
        }).then(res => res.json())
        .then((res) => {
            console.log("saveData", res);
            updatePage(1);
        })
    }

    var goHome = () => {
        navigate('/');
    }
    return (
        <>
            <br />
            <ProgressBar value={((page + 1) / (pageLength)) * 100 } />
            <br />
            <center>
                <div className="container">
                    {questionShow && 
                    
                        <div 
                            className="row main-question-row"
                            style={{
                                backgroundColor: "white",
                                width: "80%",
                                padding: "30px",
                                textAlign: "left",
                                // marginBottom: "100px",
                            }}
                        >
                            <div className="col">
                                <div className="row">
                                    <div className="col" style={{borderBottom: "1px solid lightgrey"}}>
                                        <p style={{
                                            color: "#a12525",
                                            fontWeight: "bold",
                                        }}>
                                            {pages[page]?.question}
                                            {pages[page]?.statement}
                                        </p>
                                    </div>
                                </div>
                                <br/> 
                                <QuestionBody
                                    page={pages[page]}
                                    changeRadioValues={changeRadioValues}
                                    changeCheckboxValues={changeCheckboxValues}
                                    updateTextValue={updateTextValue}
                                    updateInputsValues={updateInputsValues}
                                    updateFilesValue={updateFilesValue}
                                />
                                <br />
                                <QuestionButtons 
                                    page={pages[page]}
                                    updatePage={updatePage}
                                    saveData={saveData}
                                    goHome={goHome}
                                    disableButton={disableButton} 
                                />
                            </div>
                        </div>
                    }
                </div>
            </center>
        </>
    );
}
 
export default QuestionBox;
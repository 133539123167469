const FourBannersPoints = (
    {point, color}
) => {
    return (
        <>
            <div className="row">
                <div className="col-2">
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "100%",
                            backgroundColor: `${color}`,
                        }}
                    ></div>
                </div>
                <div className="col-10">
                    <h5 style={{ marginTop: '-3px', marginLeft: '-15px' }}>{point}</h5>
                </div>
            </div>
        </>
    );
}
 
export default FourBannersPoints;
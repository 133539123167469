import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Questions from './components/questions/Questions';
import Login from './components/admin/Login';
import AdminHome from './components/admin/Home';
import Client from './components/admin/client/Client';
import DownloadFile from './components/admin/client/DownloadFile';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/getting-started" element={<Questions />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/client/:id" element={<Client />} />
        <Route path="/admin/download-file/:id/:type" element={<DownloadFile />} />
      </Routes>
    </Router>
  );
}

export default App;

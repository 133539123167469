import image1 from "../../images/long-banner-1.jpg";
import image2 from "../../images/long-banner-2.jpg";
import image3 from "../../images/long-banner-3.jpg";
import image4 from "../../images/long-banner-4.jpg";
import image5 from "../../images/long-banner-5.jpg";

const LongBanner = () => {
    var images = [
        image1,
        image2,
        image3,
        image4,
        image5,
    ]
    return (
        <>
            <section className="container-fluid" style={{
                backgroundColor: "white",
                padding: "30px",
            }}>
                <div className="container">
                    <center>
                        <div className="row">
                            <div className="col">
                                <h2>Representing the Largest Trucking Carriers</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p style={{fontSize: "22px"}}>
                                    As an independent agency, we have access to 250+ insurance companies and brokers. 
                                    This allows us to find multiple solutions for you quickly and efficiently.
                                </p>
                            </div>
                        </div>
                        <br /><br />
                        <div className="row">
                            {images.map((image)=>(
                                <div className="col-12 col-md">
                                    <img 
                                        src={image}
                                        alt="long-banner"
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            marginTop: "5px",
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </center>
                </div>
            </section>
        </>
    );
}
 
export default LongBanner;
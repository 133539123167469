import FourBannersPoints from "./FourBannersPoints";

import image1 from "../../images/four-banners-1.jpg";
import image2 from "../../images/four-banners-2.jpg";
import image3 from "../../images/four-banners-3.jpg";
import image4 from "../../images/four-banners-4.png";

const FourBanners = () => {
    var banners = [
        {
            backgroundImage: image1,
            number: "01",
            color: "lightgreen",
            heading: "Auto Liability",
            points: [
                "Other Commercial Auto",
                "Towing & Repossession",
                "Trucking",
            ],
        },
        {
            backgroundImage: image2,
            number: "02",
            color: "red",
            heading: "General Liability",
            points: [
                "Excess GL limits",
                "Medical Expense Coverage",
                "Personal & Adertising Injury",
            ],
        },
        {
            backgroundImage: image3,
            number: "03",
            color: "silver",
            heading: "Motor Truck Cargo",
            points: [
                "Excess Cargo Limits",
                "Refrigeration Breakdown",
                "Trailer Interchange",
            ],
        },
        {
            backgroundImage: image4,
            number: "04",
            color: "orange",
            heading: "Physical damage",
            points: [
                "Comprehensive",
                "Collision",
                "Various Deductibles",
            ],
        },
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {banners.map((banner)=>(
                        <div className="col-12 col-md-6 col-lg-3 banner-column" style={{
                            backgroundImage: `url(${banner.backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: "100%",
                            transition: 'all 0.3s ease-in-out',
                            color: "white",
                        }}>
                            <div style={{
                                padding: "30px",
                                display: "flex",
                                flexDirection: 'column-reverse',
                                height: "500px"
                            }}>
                                {banner.points.map((point, index)=>(
                                    <FourBannersPoints point={point} color={banner.color} key={index} />
                                ))}
                                <br />
                                <h2>{banner.heading}</h2>
                                <br />
                                <div style={{borderBottom: '1px solid white'}}></div>
                                <br/>
                                <h1 style={{color: `${banner.color}`, fontSize: "50px"}}>{banner.number}</h1>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
 
export default FourBanners;
import { Link } from 'react-router-dom';
import mainLogo from "../../images/main-logo.png";
import StartQuoteButtonNoBg from "../StartQuoteButtonNoBg";

const Footer = () => {
    return (
        <>
            <section className="container-fluid" style={{ backgroundColor: "#0a2857", color: "white"}}>
                <div className="container-fluid">
                    <div className="row" style={{padding: "20px"}}>
                        <div className="col-12 col-md-9">
                            {window.innerWidth < 800 &&
                                <center>
                                    <img src={mainLogo} className="logo" alt="logo" />
                                </center>
                            }
                            {window.innerWidth > 800 &&
                                <img src={mainLogo} className="logo" alt="logo" />
                            }
                        </div>
                        <div className="col-12 col-md-3">
                            <center>
                                <StartQuoteButtonNoBg style={{ float: 'right' }} />
                            </center>
                        </div>
                    </div>
                    <div className="row" style={{borderBottom: "1px solid lightblue"}}></div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <p style={{ fontSize: "22px" }}>Contact Us</p>
                            <p style={{ fontSize: "20px" }}>Commercial Truck Insurance Pros</p>
                            <span>Salt lake City Office</span> <br />
                            <span>230 South 500 East</span> <br />
                            <span>Salt Lake City, Utah 84102</span> <br />
                            <span>Phone: <a href="tel:8019099972" style={{
                                color: "white",
                            }}>801-909-9972</a></span> <br />
                            <span>Email: <a href="mailto:info@getmytruckinsured.com" style={{
                                color: "white",
                            }}>info@getmytruckinsured.com</a></span> <br /> <br /> <br />
                            {/* <Link to="/admin/login">Admin</Link> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default Footer;
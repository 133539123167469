import React, { useState } from 'react';

const QuestionsDropDown = ({ question, answers }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAnswer = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <div className="row" style={{
                backgroundColor: "white",
                color: "black",
                padding: "30px",
                borderRadius: "30px",
                cursor: "pointer",
                fontWeight: "bold",
                marginTop: "10px",
            }}
            onClick={toggleAnswer}
        >
            <div className="row" style={{width: "100%"}}>
                <div className="col-11">
                    {question}
                </div>
                <div className="col-1">
                    <span 
                        className={`arrow ${isOpen ? 'open' : ''}`}
                        style={{float: "right"}}
                    >
                            {isOpen ? '▲' : '▼'}
                    </span>
                </div>
            </div>
            {isOpen && 
                <div className="answer">
                    <br />
                    {answers?.map((answer, index)=>(
                        <div key={index}>
                            {index + 1} - {answer.answer} 
                            {answer.other_answer &&
                                <>
                                    :
                                    <u style={{marginLeft: "5px"}}>
                                        {answer.other_answer}
                                    </u> 
                                </>
                            }
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}
 
export default QuestionsDropDown;
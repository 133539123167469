import TowRowsPoints from "./TwoRowsPoints";
import image from "../../images/two-rows.jpg";
import StartQuoteButton from "../StartQuoteButton";

const TowRows = () => {
    var points = [
        { 
            heading: "Liability Insurance",
            body: "Get protection from many different types of harm.",
        },
        {
            heading: "Cargo Insurance",
            body: "Coverage for damages to cargo during transport.",
        },
        {
            heading: "Physical Damage",
            body: "Collision and comprehensive coverage can cover various claims.",
        },
        {
            heading: "Environmental Liability",
            body: "Coverage for the cost of cleanup from a spill in a claim.",
        },
    ]
    return (
        <div 
            className="container-fluid" 
            style={{
                backgroundColor: 'lightgrey',
                padding: '30px',
            }}>
            <div className="row">
                <div className="col-12 col-md-6">
                    <h2>Quality Service for Truckers</h2>
                    <br />
                    <h4>Proudly licensed and serving all 50 states</h4>
                    <br />
                    <b>
                        <p>
                            Let’s get straight to the point, insurance shopping is brutal; 
                            having to wait around for a quote after filling out an application on some 
                            website or even finding a website that offers just what you are looking for.
                        </p>
                        <p>
                            Commercial Trucking Insurance Pros takes the hassle away by working with you on a
                            personal basis and we specialize in meeting your individual needs.
                        </p>
                        <p>
                            Our trucking specialties include:
                        </p>
                    </b>
                    <br />
                    <div className="row">
                        {points.map((point, index) => (
                            <div className="col-12 col-md-6" key={index}>
                                <TowRowsPoints
                                    heading={point.heading}
                                    body={point.body}
                                />
                            </div>
                        ))};
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <StartQuoteButton
                                backgroundColor="blue"
                                color="white"
                                onHoverBgColor="cadetblue"
                                onHoverColor="white"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <img 
                        src={image}
                        alt="images"
                        style={{
                            width: "100%"
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
 
export default TowRows;
import mainLogo from "../../images/main-logo.png";
import { useNavigate } from 'react-router-dom';
import QuestionBox from "./QuestionBox";

const Questions = () => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/")
    }
    return (
        <>
            <div 
                className="container-fluid"
                style={{
                    backgroundColor: "#a12525",
                    height: "90px",
                }}
            >
                <img 
                    src={mainLogo} alt="logo" 
                    className="logo"
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={goHome}
                />
            </div>
            
            <QuestionBox />
        </>
    );
}
 
export default Questions;
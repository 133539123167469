import React, { useState } from "react";

const QuestionBody = ({ 
    page, 
    changeRadioValues, 
    changeCheckboxValues,
    updateTextValue,
    updateInputsValues,
    updateFilesValue,
}) => {
    const [filesLength, setFilesLength] = useState(0);

    var changeRadio = (args) => {
        changeRadioValues(args.target.id);
    }
    var changeCheckbox = (args) => {
        changeCheckboxValues(args.target.id);
    }
    var updateText = (args) => {
        console.log("updateText", args);
        updateTextValue(args.target.value);
    }
    var updateInputs = (args, index) => {
        updateInputsValues(args.target.value, index);
    }
    var updateFile = (args) => {
        var filesLength = updateFilesValue(args.target.files);
        setFilesLength(filesLength)
    }
    return (
        <>
            {page?.radios?.map((item, index) => (
                <div className="row" style={{marginTop: "10px"}} key={index}>
                    <div className="col" style={{position: "relative"}}>
                        <input
                            type="radio"
                            name="radio"
                            id={item?.id}
                            className="radioInput"
                            checked={item?.value}
                            onChange={changeRadio}
                        />
                        <span
                            style={{
                                marginLeft: "40px",
                                fontWeight: "bold",
                                color: "#a12525",
                            }}
                        >{item?.text}</span>
                    </div>
                </div>
            ))}

            {page?.checkboxes?.map((item, index) => (
                <div className="row" style={{ marginTop: "10px" }} key={index}>
                    <div className="col" style={{ position: "relative" }}>
                        <input 
                            type="checkbox"
                            name="checkbox"
                            id={item?.id}
                            checked={item?.value}
                            className="checkboxInput"
                            onChange={changeCheckbox}
                        />
                        <span
                            style={{
                                marginLeft: "40px",
                                fontWeight: "bold",
                                color: "#a12525",
                            }}
                        >{item?.text}</span>
                    </div>
                </div>
            ))}

            {page?.inputs?.map((input, index) => (
                <div className="col" style={{ position: "relative" }} key={index}>
                    <span
                        style={{
                            fontWeight: "bold",
                            color: "#a12525",
                        }}
                    >{input?.text}</span>
                    <br />
                    <input
                        type="text"
                        name="inputs"
                        className="textInput"
                        value={input?.value}
                        onInput={(args) => { updateInputs(args, index) }}
                        style={{marginBottom: "20px"}}
                    />
                </div>
            ))}

            {page?.radios && page?.input !== undefined && page?.radios[page?.radios?.length - 1]?.value && 
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col" style={{ position: "relative" }}>
                        <input 
                            type="text"
                            name="radio_input"
                            className="textInput"
                            value={page?.input}
                            onInput={updateText}
                        />
                    </div>
                </div>
            }

            {page?.checkboxes && page?.input !== undefined && page?.checkboxes[page?.checkboxes?.length - 1]?.value &&
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col" style={{ position: "relative" }}>
                        <input
                            type="text"
                            name="checkbox_input"
                            className="textInput"
                            value={page?.input}
                            onInput={updateText}
                        />
                    </div>
                </div>
            }

            {page?.files !== undefined && 
                <div className="col" style={{ position: "relative" }}>
                    <span
                        style={{
                            fontWeight: "bold",
                            color: "#a12525",
                        }}
                    >
                        Provide The Following: ({filesLength} files attached)
                        <ol>
                            <li key={1}>Truck information -Year , Make , Model , Vin# , Value</li>
                            <li key={2}>Trailer information - Year , Make , Model , Vin# , Value</li>
                            <li key={3}>Driver information - Copy of DL for Each driver  / Name, Date of Birth , DL# , State of issueance</li>
                            <li key={4}>Copy of Loss Run History/Claims report from Current and prior insurance carriers going back 3 years</li>
                        </ol>
                    </span>
                    <label for="fileInput" className="fileInput">Upload File</label>
                    <input type="file" id="fileInput" multiple onChange={updateFile} />
                </div>
            }
        </>
    );
}
 
export default QuestionBody;
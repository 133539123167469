const QuestionButtons = ({ page, updatePage, saveData, goHome, disableButton }) => {
    console.log("page", page);
    return (
        <>
            <center>
                <div className="row">
                    <div className="col">
                        {page?.backText &&
                            <button 
                                className="questionButton"
                                style={{ float: "right" }}
                                onClick={() => { updatePage(-1) }}
                            >
                                {page?.backText}
                            </button>
                        }
                    </div>
                    <div className="col">
                        {page?.nextText &&
                            <button 
                                className="questionButton" 
                                style={disableButton ? { backgroundColor: "grey", float: "left" } : { float: "left" }}
                                onClick={() => { updatePage(1) }}
                                disabled={disableButton}
                            >
                                {page?.nextText}
                            </button>
                        }

                        {page?.sendText &&
                            <button
                                className="questionButton"
                                style={disableButton ? { backgroundColor: "grey", float: "left" } : { float: "left" }}
                                onClick={() => { saveData() }}
                                disabled={disableButton}
                            >
                                {page?.sendText}
                            </button>
                        }

                        {page?.homeText &&
                            <button
                                className="questionButton"
                                style={{ float: "left" }}
                                onClick={() => { goHome() }}
                            >
                                {page?.homeText}
                            </button>
                        }
                    </div>
                    {!page?.backText &&
                        <div className="col"></div>
                    }
                </div>
            </center>
        </>
    );
}
 
export default QuestionButtons;
import StartQuoteButton from "../StartQuoteButton";

const OneBox = (
        { 
            color, icon, text, bg,
            textColor, onHoverBgColor, onHoverColor
        }
    ) => {
    return (
        <>
            <br /><br />
            <div className="one-box" style={{
                color: { textColor },
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                boxShadow: `-30px 30px 0px -5px ${color}, 0 0px 0px -5px ${color}`
            }}>

                <div className="vertical-space-7"></div>
                <div className="row">
                    <div className="col">
                        <h1 style={{
                            color: textColor,
                        }}>{text}</h1>
                    </div>
                </div>
                <div className="vertical-space-7"></div>
                <div className="row">
                    <div className="col-12 col-md"></div>
                    <div className="col-12 col-md-6">
                        <StartQuoteButton 
                            backgroundColor = "black"
                            color = {textColor}
                            onHoverBgColor = {onHoverBgColor}
                            onHoverColor = {onHoverColor}
                        />
                    </div>
                    <div className="col-12 col-md"></div>
                </div>
            </div>
        </>
    );
}
 
export default OneBox;
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const StartQuoteButton = (
    {
        backgroundColor,
        color,
        onHoverBgColor,
        onHoverColor,
    }
) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: isHovered ? onHoverBgColor : backgroundColor,
        color: isHovered ? onHoverColor : color
    }

    const navigate = useNavigate();
    const handleClick = () => {
        // Redirect to the desired route
        navigate('/getting-started');
    };
    return (
        <button className="start-quote" 
            style={backgroundColor ? buttonStyle : {}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClick}
        >Start Quote</button>
    );
}
 
export default StartQuoteButton;